import React, { Component } from "react";
import styled, { css } from "styled-components";
import Header from "../components/mui/Header";
import Footer from "../components/mui/Footer";
import { Typography } from "@mui/material";

function Settings(props) {
  return (
    <>
      <Typography>Upsss not coded!!</Typography>
    </>
  );
}

export default Settings;
