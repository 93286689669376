import ApiRequest from "./ApiRequest";
import ResponseHandler from "./ResponseHandler";
import State from "./state";

export default class UsersAPI extends ApiRequest {
    constructor() {
        super();
        this.relPath = '/users';
    }

    //static users = new UsersAPI() ?

    async login(formData) {
        // return ResponseHandler.handle(await super.post('/login', formData),
        return ResponseHandler.handle(await super.post('/migration/login', formData),
        (data) => {
            State.setToken(data.response.token)
        }
        ,(error) => {
            
        })
    }

    async signup(formData) {
        return ResponseHandler.handle(await super.post('/migration/signup', formData))
    }

    async getMe() {
        return await super.get('/migration/me')
    }

    async follow(username) {
        return ResponseHandler.handle(await super.get(`/migration/follow/${username}`))
    }
    
    async unfollow(username) {
        return ResponseHandler.handle(await super.get(`/migration/unfollow/${username}`))
    }

    async accept(username) {
        return ResponseHandler.handle(await super.get(`/migration/accept/${username}`))
    }

    async reject(username) {
        return ResponseHandler.handle(await super.get(`/migration/reject/${username}`))
    }

    async updateProfile(formdata) {
        let customHeaders = new Headers();
        customHeaders.append("Content-Type", "multipart/form-data; boundary=<calculated when request is sent>");
        customHeaders.append("auth-token", State.getToken());
        return ResponseHandler.handle(await super.post(`/migration/update/me`, formdata, customHeaders))
    }
    
}