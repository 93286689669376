import React, { useEffect, useState } from "react";
import styled from "styled-components";
import State from "../../api/state";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { useNavigate } from "react-router-dom";
// import { Menu, MenuItem } from "@mui/material";
import { CancelRounded, CheckCircleOutline, MoreVert as MoreVertIcon } from "@mui/icons-material";
// import { ToastContainer, toast } from 'react-toastify';
import UsersAPI from "../../api/UsersAPI";
import { Button, IconButton, MenuItem } from "@mui/material";

const ProfileCard = styled(Card)`
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;

  .profile-info {
    flex-grow: 1;
    margin-left: 1rem;

    h3 {
      font-size: 1.2rem;
      font-weight: bold;
      margin-bottom: 0.25rem;
    }

    p {
      font-size: 0.875rem;
      color: #888888;
    }
  }
`;

function ProfilePendingResult({ profile }) {
  const me = State.getMe().user
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null)
  const handleProfileClick = (profileName) => {
    console.log("Navigating");
    navigate(`/ProfileOther/${profileName}`);
  };

  const handleContextMenuOpen = (e) => {
    e.preventDefault();
    setMenuAnchorEl(e.currentTarget);
  };

  const handleContextMenuClose = (e) => {
    try {
      const actions = {
        'reject': async (username) => {
          new UsersAPI().reject(username)
        },
        'accept': async (username) => {
          new UsersAPI().accept(username)
        }
      }
      const accessKey = e.target.getAttribute('accessKey')
      actions[e.target.getAttribute('itemID')](accessKey)
      setMenuAnchorEl(null);
    } catch (err) {
      console.error('[Search][Menu] MissClick')
      setMenuAnchorEl(null);
    }
  };


  return (
    me.social.followedRequest.map(i => i.username).includes(profile.username)) ?
    <ProfileCard onContextMenu={handleContextMenuOpen} style={{ backgroundColor: "#85929e4d"}}>
      <Avatar src={State.fileshost + profile.profilePhoto} alt={profile.username} />
      <CardContent className="profile-info" onClick={() => handleProfileClick(profile.email)}>
        <Typography variant="h6" component="h3">
          {profile.username} <Typography variant="p" component="p">wants to follow you</Typography>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {currentLocation} {profile.social.followers.length} followers
        </Typography>
      </CardContent>
      <div className="follow-actions">
        {me.social.followedRequest.map(i => i.username).includes(profile.username) && (
          <>
            <Button
              // style={{ borderRadius: '4px', backgroundColor: 'blue', color: 'white', marginRight: '8px' }}
              variant="contained"
              onClick={handleContextMenuClose} itemID="accept" accessKey={profile.username}
            >
              Confirm
            </Button>
            <MoreVertIcon style={{ cursor: "pointer" }} onClick={handleContextMenuOpen} >
              <MenuItem onClick={handleContextMenuClose} itemID="reject" accessKey={profile.username}>Reject</MenuItem>
            </MoreVertIcon>
          </>
        )}
      </div>
    </ProfileCard>

    : null

}

export default ProfilePendingResult;


// .put("Lat", lastLat)
// .put("Lng", lastLng)
// .put("Address", lastAddress)
// .put("Vacc", lastVacc)
// .put("Hacc", lastHacc)
// .put("Alt", lastAlt)
// .put("Speed", lastSpeed)
// .put("SpeedAcc", lastSpeedAcc)