import React, { useState, useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Polyline, useMapEvents, Popup, useMap } from 'react-leaflet';
import Button from "@mui/material/Button";
import { IconButton, Tooltip, Box, TextField, FormControl, Grid, Menu, MenuItem, Modal, Typography, InputLabel, Select } from '@mui/material';
import { CenterFocusStrong, Save, Delete, CloudUpload, Architecture, Undo, Edit } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import L from 'leaflet';
import markerIcon from './src/marker1.png';
import 'leaflet/dist/leaflet.css';
import RuttApi from '../../api/RuttApi';
import { useNavigate } from 'react-router-dom';
import FloatingAction from './utils/FloatingAction';


const RouteMap = ({ rutt, belongsToUser, show }) => {
  const [drawMode, setDrawMode] = useState('polyline')
  const [markers, setMarkers] = useState([]);
  const [coordinates, setCoordinates] = useState([]);
  const [center, setCenter] = useState([0, 0]);
  const [mapKey, setMapKey] = useState(0);
  const [mapZoom, setMapZoom] = useState(13);
  const [firstTimeMapView, setFirstTimeMapView] = useState(true)
  let mapRef = useRef(null)
  const [drawingRoute, setDrawingRoute] = useState(false);
  const customIcon = L.icon({
    iconUrl: markerIcon,
    iconSize: [32, 32], // Adjust the size as needed
    iconAnchor: [16, 32], // Adjust the anchor point if necessary
  });

  const [optHeightPx, setOptHeightPx] = useState(() => {
    const documentHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );

    const headerHeight = document.getElementById('appheader').offsetHeight
    const footerHeight = document.getElementById('appfooter').offsetHeight

    const finalSizeInPx = documentHeight - headerHeight - footerHeight

    return finalSizeInPx
    // return '200px'
  })

  useEffect(() => {
    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.debug('[Rutt]: Your position', latitude, longitude);
          setCenter([latitude, longitude]);
          setMapKey((prevKey) => prevKey + 1); // Update the map key to force re-render
        },
        (error) => {
          console.error('[Rutt] Geolocation failed', error);
          if (!rutt) {
            console.debug('[Rutt] Using default coordinates')
            setCenter([43.370731, -8.395850]);
            setMapKey((prevKey) => prevKey + 1); // Update the map key to force re-render
          } else {
            console.debug('[Rutt] Geolocation service failed and using default coordinates was skipped because a rutt is ready to be loaded.')
          }
        }
      );
    }
    if (rutt) {
      //setFirstTimeMapView because is a loaded map
      setFirstTimeMapView(false)

      console.debug('[Rutt] Rendering rutt data', rutt)
      setCoordinates(rutt.raw.coordinates || [])
      setMarkers(rutt.raw.markers || [])
      setCenter(rutt.raw.center || [43.370731, -8.395850])
      setNonPolylineMarkers(rutt.raw.nonPolylineMarkers || [])
      setMapZoom(rutt.raw.zoom || 13)
      //ruttData
      setRuttName(rutt.title)
      setAccessType(rutt.data.accessType)
      setEventType(rutt.data.eventType)
      setDuration(rutt.data.duration)
      setPrice(rutt.data.price)
      //WARNING DEPRECATED USAGE OF ATTRIBUTES
      setRuttDateTimeFrom(rutt.data.datetimefrom || '2023-05-19:22:14')
      setRuttDateTimeTo(rutt.data.datetimeto || '2023-05-19:22:14')
      setPolylineKey((prevKey) => prevKey + 1); // Trigger re-render of Polyline
      setMapKey((prevKey) => prevKey + 1);
    }

  }, []);
  const [nonPolylineMarkers, setNonPolylineMarkers] = useState([])
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const handleMapClick = (event) => {
    if (!belongsToUser) {
      console.debug('[Rutt] Click is disabled on view mode')
      return false
    }
    //Right Click
    if (event.originalEvent.button === 0) {
      console.debug('[Rutt] Drawmode changed to', drawMode)
      if (drawMode == 'polyline') {
        if (!drawingRoute) {
          const { lat, lng } = event.latlng;
          const newMarker = {
            latlng: event.latlng,
            id: new Date().getTime(), // Generate a unique ID for the marker
          };

          setMarkers((prevMarkers) => [...prevMarkers, newMarker]);
          setCoordinates((prevCoordinates) => [...prevCoordinates, [lat, lng]]);
        }
      } else if (drawMode == 'point') {
        const newMarker = {
          latlng: event.latlng,
          id: new Date().getTime(), // Generate a unique ID for the marker
          text: 'unnamed',
          color: 'default'
        };
        setNonPolylineMarkers((prevMarkers) => [...prevMarkers, newMarker]);
      } else {
        console.debug('[Rutt] No such a drawmode avaliable')
      }
    } else {
      console.debug('[Rutt][Event] LeftClick')
    }
  };

  const handleMouseDown = () => {
    console.debug('[Rutt][Event] MouseDown', mapRef)
    setDrawingRoute(true);
  };

  const handleMouseUp = () => {
    console.debug('[Rutt][Event] MouseUp')
    setDrawingRoute(false);
  };

  const handleMouseMove = (event) => {
  };

  const handleDeleteLastPoint = () => {
    console.debug('[Rutt] Undo point')
    if (coordinates.length > 0) {
      setMarkers((prevMarkers) => prevMarkers.slice(0, -1));
      setCoordinates((prevCoordinates) => prevCoordinates.slice(0, -1));
    }
  };

  const polylineRef = useRef(null);
  const [polylineKey, setPolylineKey] = useState(0);


  const centerToCurrentLocation = () => {
    // Get user's current location
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter([latitude, longitude]);
          setMapKey((prevKey) => prevKey + 1); // Update the map key to force re-render
        },
        (error) => {
          console.error(error);
        }
      );
    }
  };

  const MapEvents = () => {
    useMapEvents({
      click: handleMapClick,
      mousedown: handleMouseDown,
      mouseup: handleMouseUp,
      mousemove: handleMouseMove,
      zoomend: () => { setMapZoom(mapRef.getZoom()) },
      moveend: () => {
        try {
          setCenter([mapRef._lastCenter.lat, mapRef._lastCenter.lng])
        } catch (error) {
          console.error('[Rutt] _lastCenter error', error)
        }
      }
    });

    return null;
  };
  const [clickedMarker, setClickedMarker] = useState(null);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const handleMenuClose = () => {
    setClickedMarker(null);
    setMenuAnchor(null);
  };
  const [markerId, setMarkerId] = useState()
  const handleMarkerClick = (markerId, event) => {
    console.debug('[Rutt][handleMarkerClick] markerId', markerId)
    setMarkerId(markerId)
    setMousePosition({ x: event.originalEvent.clientX, y: event.originalEvent.clientY });
    setClickedMarker(markerId);
    setMenuAnchor(event.currentTarget);
  };
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newMarkerName, setNewMarkerName] = useState('');
  const handleRenameMarker = () => {
    if (drawMode == 'point') {
      const marker = nonPolylineMarkers.find((marker) => marker.id === markerId);
      if (marker) {
        setClickedMarker(marker.id);
        setIsRenameModalOpen(true);
        setNewMarkerName(marker.text);
      } else {
        console.error('[Rutt] Selected marker does not exist/couldnt be found')
      }
      setMenuAnchor(null);
    }else{
      const marker = markers.find((marker) => marker.id === markerId);
      if (marker) {
        setClickedMarker(marker.id);
        setIsRenameModalOpen(true);
        setNewMarkerName(marker.text);
      } else {
        console.error('[Rutt] Selected marker does not exist/couldnt be found')
      }
      setMenuAnchor(null);
    }

  }

  const handleRenameMarkerSubmit = () => {
    if (drawMode == 'point') {
      const updatedMarkers = nonPolylineMarkers.map((marker) => {
        if (marker.id === clickedMarker) {
          return { ...marker, text: newMarkerName };
        }
        return marker;
      });
      setNonPolylineMarkers(updatedMarkers);
      setIsRenameModalOpen(false);
    } else {
      const updatedMarkers = markers.map((marker) => {
        if (marker.id === clickedMarker) {
          return { ...marker, text: newMarkerName };
        }
        return marker;
      });
      setMarkers(updatedMarkers);
      setIsRenameModalOpen(false);
    }
  };
  const handleDeleteCoordinate = () => {
    console.debug('[Rutt][handleDeleteCoordinate] previous markerId', markerId)
    const selectedMarker = markers.find((marker) => marker.id === markerId);
    console.debug('[Rutt][handleDeleteCoordinate] selected marker', selectedMarker)
    setMarkers((prevMarkers) => prevMarkers.filter((marker) => marker.id !== clickedMarker));
    setCoordinates((prevCoordinates) =>
      prevCoordinates.filter((coordinate) => {
        const [lat, lng, markerId] = coordinate;
        return !(lat === selectedMarker.latlng.lat && lng === selectedMarker.latlng.lng);
      })
    );
    setMenuAnchor(null);
  };
  const handleDeleteOnlyMarker = () => {
    setMarkers((prevMarkers) => prevMarkers.filter((marker) => marker.id !== clickedMarker));
    setCoordinates((prevCoordinates) =>
      prevCoordinates.filter((coordinate) => coordinate[2] !== clickedMarker)
    );
    setMenuAnchor(null);
  };
  //DRAW MODE MENU
  const [menuDrawAnchor, setMenuDrawAnchor] = useState(null);
  const handleDrawModeChange = (mode) => {
    setDrawMode(mode);
    setMenuDrawAnchor(null);
  };

  const handleMenuDrawOpen = (event) => {
    setMenuDrawAnchor(event.currentTarget);
  };

  const handleMenuDrawClose = () => {
    setMenuDrawAnchor(null);
  };

  const [ruttName, setRuttName] = useState(' ')
  const [ruttDateTimeFrom, setRuttDateTimeFrom] = useState(' ')
  const [ruttDateTimeTo, setRuttDateTimeTo] = useState(' ')
  const [eventType, setEventType] = useState(' '); // private or public
  const [accessType, setAccessType] = useState(' '); // private, public, pay
  const [price, setPrice] = useState(' ');
  const [duration, setDuration] = useState(' ');
  const navigate = useNavigate()
  const ruttApi = new RuttApi()
  async function handleUploadRutt() {

    let ruttFile = {
      coordinates: coordinates,
      markers: markers,
      nonPolylineMarkers: nonPolylineMarkers,
      center: center,
      zoom: mapZoom,
      ruttData: {
        name: ruttName,
        datetimefrom: ruttDateTimeFrom,
        datetimeto: ruttDateTimeTo,
        duration: duration,
        eventType: eventType,
        accessType: accessType,
        price: price
      }
    };
    const { data, succeeded } = (await ruttApi.notificationContext(show).uploadRutt(ruttFile))
    navigate('/RuttView/' + data.ruttId)

  }

  function handlePublishRutt() {

  }

  function handleUpdateRutt() {
    let ruttFile = {
      raw: {
        coordinates: coordinates,
        markers: markers,
        nonPolylineMarkers: nonPolylineMarkers,
        center: center,
        zoom: mapZoom,
      }
      // data: {
      //   name: ruttName,
      //   datetimefrom: ruttDateTimeFrom,
      //   datetimeto: ruttDateTimeTo,
      //   duration: duration,
      //   eventType: eventType,
      //   accessType: accessType,
      //   price: price
      // }
    };
    new RuttApi().notificationContext(show).updateRutt(ruttFile, rutt._id)
  }
  const handleFloatingClick = (event) => {
    event.preventDefault()
  }
  return (
    <div>
      <Modal open={isRenameModalOpen} onClose={() => setIsRenameModalOpen(false)}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <Box sx={{ width: 300, p: 2, backgroundColor: 'white' }}>
            <Typography variant="h6" gutterBottom>
              Rename Marker
            </Typography>
            <TextField
              label="New Marker Name"
              value={newMarkerName}
              onChange={(event) => setNewMarkerName(event.target.value)}
              fullWidth
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
              <Button onClick={handleRenameMarkerSubmit} variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      {/* {belongsToUser ?
          <Tooltip title="Load Polyline from File">
            <IconButton component="label" style={{ backgroundColor: 'white' }}>
              <CloudUpload />
              <input type="file" accept=".json" style={{ display: 'none' }} onChange={handleFileUpload} />
            </IconButton>
          </Tooltip>
        </Box> : false} */}

      <MapContainer
        key={mapKey} // Use a unique key to force re-render when center changes
        center={center} // Set the initial center of the map
        zoom={mapZoom} // Set the initial zoom level
        style={{ height: optHeightPx, width: '100%' }}
        ref={(map) => (mapRef = map)}
      >
        <MapEvents />

        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

        {markers.map((marker) => (
          <Marker icon={customIcon} position={marker.latlng} key={marker.id}
            eventHandlers={{
              click: (event) => handleMarkerClick(marker.id, event),
            }}
          >
            <Popup>{marker.text}</Popup>
          </Marker>
        ))}

        {nonPolylineMarkers.map((marker) => (
          <Marker
            icon={customIcon}
            position={marker.latlng}
            key={marker.id}
            eventHandlers={{
              click: (event) => handleMarkerClick(marker.id, event),
            }}
          >
            <Popup>{marker.text}</Popup>

          </Marker>
        ))}

        <Polyline positions={coordinates} key={polylineKey} ref={(ref) => (polylineRef.current = ref)} />

        {belongsToUser ? <Menu
          open={menuAnchor !== null}
          anchorEl={menuAnchor}
          onClose={handleMenuClose}
          style={{
            position: 'fixed',
            left: mousePosition.x,
            top: mousePosition.y,
          }}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <MenuItem onClick={handleDeleteOnlyMarker}>Remove marker</MenuItem>
          <MenuItem onClick={handleDeleteCoordinate}>Remove point</MenuItem>
          <MenuItem onClick={handleRenameMarker}>Rename</MenuItem>
        </Menu> : false}

      </MapContainer>

      {belongsToUser ?
        <>
          <FloatingAction bottomSpacing={8} rightSpacing={1} clickHandler={() => { }} btref="drawmode" place="3" undecorated={false}>
            <Tooltip title="Change Draw Mode">
              <Button
                onClick={handleMenuDrawOpen}
                variant="contained"
                color="inherit"
                // icon={}
                style={{ backgroundColor: 'transparent', boxShadow: 'none' }}
              >
                <Architecture />
                {/* <Typography fontSize={4}>{drawMode}</Typography> */}
              </Button>
            </Tooltip>

            <Menu
              anchorEl={menuDrawAnchor}
              open={Boolean(menuDrawAnchor)}
              onClose={handleMenuDrawClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem onClick={() => handleDrawModeChange('polyline')}>Polyline</MenuItem>
              <MenuItem onClick={() => handleDrawModeChange('point')}>Point</MenuItem>
              <MenuItem onClick={() => handleDrawModeChange('polygon')}>Polygon</MenuItem>
            </Menu>
          </FloatingAction>

          <FloatingAction bottomSpacing={8} rightSpacing={1} clickHandler={handleFloatingClick} btref="save" place="2">
            <Tooltip title="Delete Last Point">
              <IconButton onClick={handleDeleteLastPoint} style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                <Undo style={{ color: 'white' }} />
              </IconButton>
            </Tooltip>
          </FloatingAction>

          {firstTimeMapView ?
            <FloatingAction bottomSpacing={8} rightSpacing={1} clickHandler={handleFloatingClick} btref="center" place="5">
              <Tooltip title="Create a new map">
                <IconButton onClick={handleUploadRutt} style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                  <AddCircleOutlineIcon style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            </FloatingAction>
            :
            <FloatingAction bottomSpacing={8} rightSpacing={1} clickHandler={handleFloatingClick} btref="center" place="4">
              <Tooltip title="Save changes">
                <IconButton onClick={handleUpdateRutt} style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                  <Save style={{ color: 'white' }} />
                </IconButton>
              </Tooltip>
            </FloatingAction>
          }

        </>
        : false
      }
      <FloatingAction bottomSpacing={8} rightSpacing={1} clickHandler={handleFloatingClick} btref="center" place="1">
        <Tooltip title="Center to Current Location">
          <IconButton onClick={centerToCurrentLocation} style={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
            <CenterFocusStrong style={{ color: 'white' }} />
          </IconButton>
        </Tooltip>
      </FloatingAction>

      {/* <Button onClick={handleUpdateRutt} variant="contained" color="primary">
        Save Changes
      </Button>
      <Button onClick={handleUploadRutt} variant="contained" color="primary">
        Create
      </Button>
      <Button onClick={handlePublishRutt} variant="contained" color="primary">
        Publish
      </Button> */}

      {belongsToUser ?
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Box width={400}>
            <TextField label="Rutt Name" variant="outlined" fullWidth margin="normal" value={ruttName} onChange={(e) => setRuttName(e.target.value)} />

            <TextField
              label="From Date & Time"
              type="datetime-local"
              variant="outlined"
              fullWidth
              margin="normal"
              value={ruttDateTimeFrom}
              onChange={(e) => setRuttDateTimeFrom(e.target.value)}
            />

            <TextField
              label="To Date & Time"
              type="datetime-local"
              variant="outlined"
              fullWidth
              margin="normal"
              value={ruttDateTimeTo}
              onChange={(e) => setRuttDateTimeTo(e.target.value)}
            />

            <TextField
              label="Duration"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
              fullWidth
              margin="normal"
            />

            <FormControl fullWidth margin="normal">
              <InputLabel id="event-type-label">Event Type</InputLabel>
              <Select
                labelId="event-type-label"
                id="event-type-select"
                value={eventType}
                onChange={(e) => setEventType(e.target.value)}
              >
                <MenuItem value="private">Private</MenuItem>
                <MenuItem value="public">Public</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel id="access-type-label">Access Type</InputLabel>
              <Select
                labelId="access-type-label"
                id="access-type-select"
                value={accessType}
                onChange={(e) => setAccessType(e.target.value)}
              >
                <MenuItem value="private">Private</MenuItem>
                <MenuItem value="public">Public</MenuItem>
                <MenuItem value="pay">Pay</MenuItem>
              </Select>
            </FormControl>

            {accessType === 'pay' && (
              <TextField
                label="Price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                fullWidth
                margin="normal"
              />
            )}
            <Button onClick={handleUpdateRutt} variant="contained" color="primary">
              Save Changes
            </Button>
            <Button onClick={handleUploadRutt} variant="contained" color="primary">
              Create
            </Button>
            <Button onClick={handlePublishRutt} variant="contained" color="primary">
              Publish
            </Button>
          </Box>
        </Box> : <></>

      }

    </div>
  );
};

export default RouteMap;
