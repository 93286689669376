import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { Typography } from "@mui/material";
import FloatingAction from "../components/mui/utils/FloatingAction";
import Notification from "../components/mui/utils/Notification";

import { useNavigate } from "react-router-dom"; // Import useHistory hook

import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import ProfileSearchResult from "../components/mui/ProfileSearchResult";
import ProfilePendingResult from "../components/mui/ProfilePendingResult";
import State from "../api/state";
import ProfileAPI from "../api/ProfileAPI";
import Loading from "../components/mui/Loading";
// import ProfileSearchResult from "../components/mui/ProfileFriendResult";

function Favorites(props) {
  const opt = {
    vertical: 'bottom',
    spacing: 8,
    message: 'Not coded',
    displayForEver: false,
    lifetime: 1000,
    severity: 'info'
  }
  const SearchContainer = styled(Container)`
  margin-top: 2rem;
  margin-bottom: 2rem;
  background-color: transparent;
  border-radius: 17px;
`;

  const ResultsContainer = styled(Container)`
  background-color: transparent;
  border-radius: 17px;
  padding: 1rem;
  margin-top: 1rem;
`;
  const [profiles, setProfiles] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [me, setMe] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        console.log('Requesting server....')
        // Execute your first request
        const currentMe = await State.getAsyncMeUpdated();
        setMe(currentMe.user);

        // Execute your second request
        const fetchedProfiles = await new ProfileAPI().getProfilesIncluding('email');

        // Discard my own profile
        setProfiles(fetchedProfiles.filter(user => user.username !== currentMe.user.username));
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching profile:", error);
      }
    };

    // Only execute fetchProfile once when the component mounts
    if (isLoading) {
      fetchProfile();
    }
  }, [isLoading]); // Add isLoading as a dependency to re-trigger the effect when it changes

  return (
    <>
      <Typography
        variant="h4" // Adjust the variant to set the text size
        align="center" // Align the text to the center
        sx={{
          backgroundColor: "white",
          display: "flex",
          justifyContent: "center", // Center the text horizontally
          alignItems: "center", // Center the text vertically
          textAlign: "center", // Align the text to center if it spans multiple lines
          padding: "1rem", // Add padding to ensure the text is properly centered
        }}
      >
        People
      </Typography>

      {isLoading ? (
        <Loading label="Searching..." />
      ) : (
        <>
          <ResultsContainer>
            {profiles.map((profile) => (
              <ProfilePendingResult
                key={profile.email}
                profile={profile}
              />
            ))}
          </ResultsContainer>
          <Typography
            variant="h4" // Adjust the variant to set the text size
            align="center" // Align the text to the center
            sx={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center", // Center the text horizontally
              alignItems: "center", // Center the text vertically
              textAlign: "center", // Align the text to center if it spans multiple lines
              padding: "1rem", // Add padding to ensure the text is properly centered
            }}
          >
            You are following
          </Typography>
          <ResultsContainer>
            {profiles.filter(profile =>
              profile.social.followers.map(follower => follower.username).includes(me.username))
              .map((profile) => (
                <ProfileSearchResult
                  key={profile.email}
                  profile={profile}
                  isBeingFollowed={true}
                />
              ))}
          </ResultsContainer>
          <Typography
            variant="h4" // Adjust the variant to set the text size
            align="center" // Align the text to the center
            sx={{
              backgroundColor: "white",
              display: "flex",
              justifyContent: "center", // Center the text horizontally
              alignItems: "center", // Center the text vertically
              textAlign: "center", // Align the text to center if it spans multiple lines
              padding: "1rem", // Add padding to ensure the text is properly centered
            }}
          >
            Your followers
          </Typography>

          <ResultsContainer>
            {profiles.filter(profile =>
              profile.social.followed.map(follower => follower.username).includes(me.username))
              .map((profile) => (
                <ProfileSearchResult
                  key={profile.email}
                  profile={profile}
                  isFollower={true}
                />
              ))}
          </ResultsContainer>

        </>
      )}
      {/* <Notification properties={opt}></Notification> */}
    </>
  );
}

export default Favorites;
