import "./styles.css";

export default function App() {
  return (
    <div className="App">
      <h1>Hello2 CodeSandbox2</h1>
      <h2>Start2 editing2 to2 see2 some2 magic2 happen!2</h2>
    </div>
  );
}
