import React, { Component } from "react";
import styled, { css } from "styled-components";
import Header from "../components/mui/Header";
import Footer from "../components/mui/Footer";
import LogIn from "../components/mui/LogIn";


function Auth(props) {
  return (
    <>
      <LogIn></LogIn>
    </>
  );
}

export default Auth;